import React, { useEffect, useRef, useState } from 'react';
import {
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetOne,
  useNotify,
} from 'react-admin';
import { getUser, httpClient } from '../../utils/tools';

export const status = [
  { id: 'on', name: 'ON' },
  { id: 'hidden', name: 'HIDDEN' },
  { id: 'disabled', name: 'DISABLED' },
  { id: 'off', name: 'OFF' },
];

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SettingEdit = (props: any) => {
  const [isDisable, setIsDisable] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const notify = useNotify();

  const textInput = useRef(null);
  useEffect(() => {
    //@ts-ignore
    if (!isDisable && textInput.current) textInput.current.value = '';
  }, [isDisable]);

  const { data: setting } = useGetOne('settings', props.id);
  useEffect(() => {
    setIsDisable(!!(setting?.status === 'disabled'));
  }, [setting]);

  const onSave = async (data: any) => {
    if (data.status === 'disabled' && !data.notes) {
      notify('Notes is required!', 'error');
      return;
    }

    try {
      await httpClient(`${apiUrl}/settings/${data.id}`, {
        method: 'PUT',
        body: JSON.stringify({
          ...data,
          notes: data.status !== 'disabled' ? '' : data.notes,
        }),
        ...getUser(),
      });
      props.history.push(props.basePath);
    } catch (error) {
      // @ts-ignore
      notify(error.message, 'error');
    }
  };

  return (
    <>
      <Show {...props} actions={null}>
        <SimpleForm save={onSave} toolbar={<CustomToolbar />}>
          <TextInput source="name" disabled />
          <SelectInput
            onChange={e => setIsDisable(!!(e.target.value === 'disabled'))}
            source="status"
            choices={status}
          />
          {isDisable && (
            <TextInput multiline source="notes" ref={textInput} required />
          )}
        </SimpleForm>
      </Show>
    </>
  );
};
