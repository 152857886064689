import { Helmet } from 'react-helmet';
import {
  Datagrid,
  List,
  TextField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';

const RolesList = (props: any) => (
  <List title="Admins" exporter={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Roles</title>
      </Helmet>
      <TextField label="Roles ID" source="id" />
      <TextField label="Roles" source="name" />
      <TextField label="Type" source="type" />
      <ReferenceArrayField
        label="Permissions"
        reference="permissions"
        source="permissionIds">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default RolesList;
