import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { Theme, useMediaQuery, Divider } from '@material-ui/core';
import { MenuItemLink, getResources, resolveRedirectTo } from 'react-admin';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { AppState } from './types';

const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const DEVIDE_ON: string[] = ['roles', 'page-checkers', 'revisions', 'plans'];

  return (
    <div>
      <MenuItemLink
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider />
      {resources.map(resource => {
        if (resource.hasList) {
          return (
            <>
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={
                  (resource.options && resource.options.label) || resource.name
                }
                leftIcon={createElement(resource.icon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
              {DEVIDE_ON.includes(resource.name) && <Divider />}
            </>
          );
        }
      })}
      {/* <MenuItemLink
        to="/seo-test"
        primaryText="SEO Test"
        leftIcon={<SearchIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      /> */}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
