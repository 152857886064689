import AdminList from './AdminList';
import AdminCreate from './AdminCreate';
import AdminShow from './AdminShow';
import AdminEdit from './AdminEdit';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

export default {
  list: AdminList,
  create: AdminCreate,
  show: AdminShow,
  edit: AdminEdit,
  icon: SupervisorAccount,
};
