import {
    SimpleForm,
    TextInput,
    required,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SelectInput,
    useNotify
} from "react-admin";
import { getUser, httpClient } from '../../utils/tools';
import { useFormState } from 'react-final-form';

// use this if want to connect 2 inputs
const PermissionInput = (props: any) => {
    const { values } = useFormState();
    return (
        <ReferenceArrayInput
            validate={[required()]}
            label="Roles"
            source="permissionIds"
            reference="permissions"
            filterToQuery={() => ({ type: values.type })} {...props}>
            <AutocompleteArrayInput reference="permissions" />
        </ReferenceArrayInput>
    );
};

const RolesCreate = (props: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const notify = useNotify();

    const onSave = async (data: any) => {
        try {
            await httpClient(`${apiUrl}/roles`, {
                method: 'POST',
                body: JSON.stringify({
                    name: data.name,
                    type: data.type
                }),
                ...getUser(),
            })
            .then(async (result: any) => {
                const { json: createdData } = result;
                await Promise.all(data.permissionIds.map(async (permission: number) => {
                    await httpClient(`${apiUrl}/roles-permissions`, {
                        method: "POST",
                        body: JSON.stringify({
                            rolesId: createdData.id,
                            permissionId: permission,
                        }),
                        ...getUser(),
                    }).catch((err) => {
                        // do nothing
                    });
                }))
                .then(() => {
                    props.history.push(props.basePath);
                });
            });

        } catch (error) {
            // @ts-ignore
            notify(error.message, 'error');
        }
    }
    
    return (
        <SimpleForm save={onSave}>
            <TextInput label="Roles" source="name" validate={[required()]} />
            <SelectInput label="Type" source="type" validate={[required()]} choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'user', name: 'User' },
            ]} />
            <ReferenceArrayInput
                validate={[required()]}
                label="Permissions"
                source="permissionIds"
                reference="permissions">
                <AutocompleteArrayInput reference="permissions" />
            </ReferenceArrayInput>
        </SimpleForm>
    );
};

export default RolesCreate;