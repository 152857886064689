import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  ReferenceField,
  EmailField,
  TextInput,
  SelectInput,
} from 'react-admin';
import StatusField from '../../components/StatusField';

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn resettable />
    <SelectInput
      source="isMainUser"
      alwaysOn
      label="Main User"
      choices={[
        { id: 1, name: 'YES' },
        { id: 0, name: 'NO' },
      ]}
    />
  </Filter>
);

const UserList = (props: any) => (
  <List
    exporter={false}
    filters={<UserFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Users</title>
      </Helmet>
      <TextField label="User ID" source="id" />
      <TextField label="First Name" source="firstName" />
      <TextField label="Last Name" source="lastName" />
      <EmailField label="Email" source="email" />
      <DateField label="Registered At" source="createdAt" showTime />
      <ReferenceField
        label="Subscription Active Until"
        source="customerId"
        reference="customers"
        link=""
        sortable={false}>
        <ReferenceField
          label="Subscription Active Until"
          source="subscriptionId"
          reference="subscriptions"
          link="">
          <DateField
            label="Subscription Active Until"
            source="subscriptionActiveUntil"
            emptyText="-"
            showTime
          />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        label="Status"
        source="customerId"
        reference="customers"
        sortable={false}
        link="">
        <StatusField label="Status" source="status" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default UserList;
