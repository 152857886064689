import {
  ChipField,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  EditButton,
  TopToolbar,
} from 'react-admin';

const CustomShowActions = ({ basePath, data }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const RolesShow = (props: any) => (
  <Show actions={<CustomShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField label="Roles ID" source="id" />
      <TextField label="Roles" source="name" />
      <TextField label="Type" source="type" />
      <ReferenceArrayField
        label="Permissions"
        reference="permissions"
        source="permissionIds">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);

export default RolesShow;
