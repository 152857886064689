import React from 'react';
import {
  Edit,
  EditProps,
  SimpleForm,
  TextField,
  TextInput,
  DateTimeInput,
  SelectInput,
  NumberInput,
  UrlField,
  DateInput,
} from 'react-admin';
import { status, plan, isWeekly } from './const';

const CustomerEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        {/* <TextField source="email" />
        <DateInput source="subscriptionActiveUntil" />
        <SelectInput source="planId" choices={plan} /> */}
        <SelectInput source="status" choices={status} />
        {/* <SelectInput
          label="Weekly Digest"
          source="isWeekly"
          choices={isWeekly}
        />
        <NumberInput source="maxSms" />
        <NumberInput source="maxWhatsapp" /> */}
      </SimpleForm>
    </Edit>
  );
};

export default CustomerEdit;
