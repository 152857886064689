import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import CustomerEdit from './CustomerEdit';
import UserIcon from '@material-ui/icons/Group';
import { EditGuesser } from 'react-admin';

export default {
  list: CustomerList,
  show: CustomerShow,
  edit: CustomerEdit,
  icon: UserIcon,
};
