import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  Filter,
  SelectInput
} from 'react-admin';
import { status } from './const';

const StatusCheckerFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      alwaysOn
      label="Status"
      choices={status}
    />
  </Filter>
);

export const StatusCheckerList = (props: any) => (
  <List
    filters={<StatusCheckerFilter />}
    exporter={false}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Status Checker ID" source="id" />
      <ReferenceField source="pageId" reference="pages">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="status" />
      <DateField label="Last Checked At" source="lastCheckedAt" showTime />
      <DateField label="Created At" source="createdAt" showTime />
    </Datagrid>
  </List>
);
