export const status = [
  { id: 'stopped', name: 'STOPPED' },
  { id: 'running', name: 'RUNNING' },
  { id: 'scheduled', name: 'SCHEDULED' },
];

export const checkerJobsStatus = [
  {
    id: 'failed',
    name: 'FAILED',
  },
  {
    id: 'success',
    name: 'SUCCESS',
  },
];

export const checkerJobsType = [
  {
    id: 'statusCheckers',
    name: 'Status Checker',
  },
  {
    id: 'formCheckers',
    name: 'Form Checker',
  },
  {
    id: 'pageCheckers',
    name: 'Page Checker',
  },
  {
    id: 'keywordCheckers',
    name: 'Keyword Checker',
  },
];
