import PlanList from './PlanList';
import PlanShow from './PlanShow';
import Icon from '@material-ui/icons/Money';
import { PlanCreate } from './PlanCreate';
import { PlanEdit } from './PlanEdit';

export default {
  list: PlanList,
  show: PlanShow,
  create: PlanCreate,
  // edit: PlanEdit,
  icon: Icon,
};
