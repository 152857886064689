import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { getUser, httpClient } from '../../utils/tools';

export const PlanCreate = (props: any) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const notify = useNotify();

  const onSave = async (data: any) => {
    try {
      const { customerIds, ...plan } = data;
      await httpClient(`${apiUrl}/plans`, {
        method: 'POST',
        body: JSON.stringify(plan),
        ...getUser(),
      }).then(async (result: any) => {
        const { json: createdData } = result;
        await Promise.all(
          customerIds.map(async (customerId: number) => {
            await httpClient(`${apiUrl}/plan-customers`, {
              method: 'POST',
              body: JSON.stringify({
                planId: createdData.id,
                customerId,
              }),
              ...getUser(),
            }).catch(err => {
              // do nothing
            });
          }),
        ).then(() => {
          props.history.push(props.basePath);
        });
      });
    } catch (error) {
      // @ts-ignore
      notify(error.message, 'error');
    }
  };

  return (
    <SimpleForm save={onSave}>
      {/* <ReferenceInput
        label="Special For"
        source="customerId"
        reference="customers">
        <SelectInput optionText="id" />
      </ReferenceInput> */}
      <ReferenceArrayInput
        validate={[required()]}
        label="Special For"
        source="customerIds"
        reference="customers">
        <AutocompleteArrayInput
          optionText="email"
          optionValue="id"
          reference="customers"
        />
      </ReferenceArrayInput>
      <TextInput source="planName" validate={[required()]} />
      <NumberInput label="Amount (in cent)" source="amount" />
      <TextInput disabled defaultValue="usd" source="currency" />
      <NumberInput source="maxStatusChecker" />
      <NumberInput source="maxLogin" />
      <NumberInput source="maxAdsAccount" />
      <BooleanInput source="isSmsWhatsappEnabled" />
      <TextInput disabled defaultValue="custom" source="planType" />
    </SimpleForm>
  );
};
