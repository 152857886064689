import _ from 'lodash';
import React from 'react';
import {
    Show,
    TextField,
    TopToolbar,
    SimpleForm,
    ShowButton,
    AutocompleteArrayInput,
    ReferenceArrayInput,
} from "react-admin";
import { getUser, httpClient } from '../../utils/tools';

const RolesEdit = (props: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const onSave = async (data: any) => {
        const actionData: Array<{
            action: string;
            id: string | number;
            data: string;
        }> = [];
        const currentPermissions = data.permissions;
        const updatePermissions = data.permissionIds;

        _.each(currentPermissions, (permission) => {
            // delete
            if (!_.includes(updatePermissions, permission.permissionId)) {
                actionData.push({
                    id: permission.id,
                    action: "DELETE",
                    data: ''
                });
            }
        });

        const mappedCurrentPermissions = _.map(data.permissions, "permissionId");
        _.each(updatePermissions, (permission) => {
            // create
            if (!_.includes(mappedCurrentPermissions, permission)) {
                actionData.push({
                    id: "",
                    action: "POST",
                    data: JSON.stringify({
                        rolesId: data.id,
                        permissionId: permission
                    })
                });
            }
        });

        await Promise.all(actionData.map(async (item) => {
            await httpClient(`${apiUrl}/roles-permissions/${item.id}`, {
                method: item.action,
                body: item.data,
                ...getUser(),
            }).catch((err) => {
                // do nothing
            });
        }))
        .then(() => {
            props.history.push(props.basePath);
        });
    }

    return (
        <React.Fragment>
            <TopToolbar>
                <ShowButton basePath={props.basePath} record={{ id: props.id }} />
            </TopToolbar>
            <Show {...props} actions={null}>
                <SimpleForm save={onSave}>
                    <TextField label="ID" source="id" />
                    <TextField label="Roles" source="name" />
                    <TextField label="Type" source="type" />
                    <ReferenceArrayInput
                        label="Permissions"
                        source="permissionIds"
                        reference="permissions">
                        <AutocompleteArrayInput reference="permissions" />
                    </ReferenceArrayInput>
                </SimpleForm>
            </Show>
        </React.Fragment>
    )
};

export default RolesEdit;