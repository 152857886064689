import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Labeled,
  ReferenceField,
  FunctionField,
  Datagrid,
  TopToolbar,
  EditButton,
  TabbedShowLayout,
  Tab,
  useRecordContext,
  Button,
  useNotify,
  useRedirect,
  ShowController,
  ReferenceManyField,
  BooleanField,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import { getUser, httpClient } from '../../utils/tools';
import { apiUrl } from '../../utils/const';
import LinkField from '../../components/LinkField';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';

const QuotaField = ({
  source,
  used,
  label,
  ...props
}: {
  label: string;
  used: string;
  source: string;
  [x: string]: any;
}) => {
  const { record } = useRecordContext<any>({});
  return (
    <div {...props} style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
        component="span"
        variant="body2">
        {label}
      </Typography>
      <Typography component="span" variant="body2">
        {record[used] ?? 0}/{record && record[source]}
      </Typography>
    </div>
  );
};

const SendWeeklyDigestButton = ({ record }: { record: any }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const res = await httpClient(`${apiUrl}/weekly-digests`, {
        method: 'POST',
        body: JSON.stringify({
          customerId: record.id,
        }),
        ...getUser(),
      });
      setIsLoading(false);
      notify('Email sent!');
    } catch (e) {
      setIsLoading(false);
    }
  };

  if (record) {
    return (
      <Button
        disabled={isLoading || !record['id']}
        color="primary"
        variant="contained"
        style={{ marginLeft: 20 }}
        onClick={handleSend}
        label="Send Weekly Digest"
        startIcon={!isLoading ? <SendIcon /> : <CircularProgress size={20} />}
      />
    );
  } else {
    return null;
  }
};

//NOTE: disable make expired button
// const MakeExpiredButton = ({ record }: { record: any }) => {
//   const [isLoading, setIsLoading] = React.useState(false);
//   const notify = useNotify();

//   const handleSend = async () => {
//     setIsLoading(true);
//     try {
//       const res = await httpClient(`${apiUrl}/weekly-digests`, {
//         method: 'POST',
//         body: JSON.stringify({
//           customerId: record.id,
//         }),
//         ...getUser(),
//       });
//       setIsLoading(false);
//       notify('Email sent!');
//     } catch (e) {
//       setIsLoading(false);
//     }
//   };

//   if (record) {
//     return (
//       <Button
//         disabled={isLoading || !record['id']}
//         color="default"
//         variant="contained"
//         style={{ marginLeft: 20 }}
//         onClick={handleSend}
//         label="Make Expired"
//         startIcon={!isLoading ? <SendIcon /> : <CircularProgress size={20} />}
//       />
//     );
//   } else {
//     return null;
//   }
// };

const EditSubsButton = ({ record }: { record: any }) => {
  const redirect = useRedirect();

  const handleRedirect = () => {
    const subscriptionId = record.subscriptionId;
    redirect(`/subscriptions/${subscriptionId}/edit`);
  };

  if (record) {
    return (
      <Button
        disabled={!record && !record['subscriptionId']}
        color="primary"
        variant="contained"
        style={{ marginLeft: 20 }}
        onClick={handleRedirect}
        label="Edit Subscription"
        startIcon={<EditIcon />}
      />
    );
  } else {
    return null;
  }
};

const CustomShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <EditSubsButton record={data} />
    <SendWeeklyDigestButton record={data} />
    {/* <MakeExpiredButton record={data} /> */}
  </TopToolbar>
);

const CustomerShow = (props: any) => (
  <ShowController {...props}>
    {controllerProps => (
      <Show
        title={'Account Detail'}
        actions={<CustomShowActions />}
        {...props}
        {...controllerProps}>
        <TabbedShowLayout>
          <Tab label="Basic Info">
            <TextField label="Account ID" source="id" />
            <DateField label="Registered At" source="createdAt" showTime />
            <DateField label="Last Update At" source="updatedAt" showTime />
          </Tab>
          <Tab label="Subscribtion Info">
            {controllerProps?.record &&
              controllerProps?.record?.status !== 'trial' && (
                <ReferenceField
                  label="Plan Name"
                  source="subscriptionId"
                  reference="subscriptions"
                  emptyText="Trial"
                  link={false}>
                  <ReferenceField
                    label="Plan Name"
                    source="planId"
                    reference="plans"
                    emptyText="Trial"
                    link={false}>
                    <TextField source="planName" />
                  </ReferenceField>
                </ReferenceField>
              )}
            {controllerProps?.record &&
              controllerProps?.record?.status === 'trial' && (
                <FunctionField
                  label="Plan Name"
                  render={() => `14 Days Trial`}
                />
              )}
            <TextField label="Card Brand" source="cardBrand" emptyText="-" />
            <TextField
              label="Card Last Four"
              source="cardLastFour"
              emptyText="-"
            />
            <TextField
              label="Card Exp Month"
              source="cardExpMonth"
              emptyText="-"
            />
            <TextField
              label="Card Exp Year"
              source="cardExpYear"
              emptyText="-"
            />
            <ReferenceField
              label="Subscription Active Since"
              source="subscriptionId"
              reference="subscriptions"
              link={false}>
              <DateField
                label="Subscription Active Since"
                source="subscriptionActiveSince"
                emptyText="-"
                showTime
              />
            </ReferenceField>
            <ReferenceField
              label="Subscription Active Until"
              source="subscriptionId"
              reference="subscriptions"
              link={false}>
              <DateField
                label="Subscription Active Until"
                source="subscriptionActiveUntil"
                emptyText="-"
                showTime
              />
            </ReferenceField>
          </Tab>
          <Tab label="Account User">
            <ReferenceManyField
              addLabel={false}
              reference="users"
              target="customerId">
              <div style={{ display: 'flex' }}>
                <Datagrid style={{ width: '100%' }}>
                  <TextField label="User ID" source="id" />
                  <TextField source="email" />
                  <DateField source="createdAt" emptyText="-" />
                  <LinkField />
                </Datagrid>
              </div>
            </ReferenceManyField>
            <ReferenceManyField
              addLabel={false}
              reference="users"
              target="customerId">
              <EmptyPlaceholder />
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )}
  </ShowController>
);

export default CustomerShow;
