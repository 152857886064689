import {
  Datagrid,
  DateField, Filter, ImageField,
  List,
  ReferenceField,
  TextField, TextInput, UrlField
} from 'react-admin';

const PageFilter = (props: any) => (
  <Filter {...props}>
      <TextInput label="Search url" source="url" alwaysOn />
  </Filter>
);

export const PageList = (props: any) => (
  <List exporter={false} bulkActionButtons={false} filters={<PageFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Page ID" source="id" />
      <ReferenceField
        label="Account Id"
        source="customerId"
        reference="customers">
        <TextField source="id" />
      </ReferenceField>
      <UrlField source="url" />
      <ImageField source="imageUrl" />
      <TextField source="name" />
      <TextField source="status" />
      <DateField label="Created At" source="createdAt" showTime />
    </Datagrid>
  </List>
);
