import React from 'react';
import {
    ReferenceField,
    Show,
    TextField,
    TopToolbar,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    ShowButton,
    useNotify
} from "react-admin";
import { getUser, httpClient } from '../../utils/tools';

const AdminEdit = (props: any) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const notify = useNotify();

    const onSave = async (data: any) => {
        try {
            await httpClient(`${apiUrl}/user-roles?userId=${data.userId}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    rolesId: data.role.rolesId,
                }),
                ...getUser(),
            });
            props.history.push(props.basePath);
        } catch (error) {
            // @ts-ignore
            notify(error.message, 'error');
        }
    }

    return (
        // <Edit {...props}></Edit>
        <React.Fragment>
            <TopToolbar>
                <ShowButton basePath={props.basePath} record={{ id: props.id }} />
            </TopToolbar>
            <Show {...props} actions={null}>
                <SimpleForm save={onSave}>
                    <TextField label="ID" source="id" />
                    <ReferenceField
                        label="Email"
                        source="userId"
                        reference="users">
                        <TextField source="email" />
                    </ReferenceField>
                    <ReferenceInput
                        label="Roles"
                        source="role.rolesId"
                        reference="roles"
                        filterToQuery={() => ({ type: "admin" })}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Show>
        </React.Fragment>
    )
};

export default AdminEdit;