import jsonExport from 'jsonexport/dist';
import {
  Datagrid, DateField, downloadCSV, Filter,
  List, ReferenceField, SelectInput, TextField, TextInput
} from 'react-admin';
import { Helmet } from 'react-helmet';
import StatusField from '../../components/StatusField';
import { getUser, httpClient } from '../../utils/tools';
import { status } from './const';

const CustomerFilter = (props: any) => (
  <Filter {...props}>
    {/* <ReferenceInput label="Plan" source="planId" reference="plans">
      <SelectInput optionText="planName" resettable />
    </ReferenceInput> 
    <ReferenceInput
      label="Email"
      source="mainUser.email"
      reference="customers"
      filterToQuery={searchText => ({ email: searchText })}>
      <AutocompleteInput optionText="email" resettable />
    </ReferenceInput>
    */}

    <TextInput label="Email" source="mainUser.email" />

    <SelectInput label="Status" source="status" choices={status} />
  </Filter>
);

const exporter = async (customers: any) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const accountsForExport = await Promise.all(
    customers.map(async (customer: any) => {
      const {
        createdAt,
        id,
        cardExpMonth,
        subscriptionId,
        stripeCustomerId,
        cardBrand,
        cardExpYear,
        cardLastFour,
        updatedAt,
        deletedAt,
        email,
        status,
        ...accountForExport
      } = customer; // omit backlinks and author

      accountForExport.account_id = id;
      accountForExport.registered_at = createdAt;

      //find the main user
      const {
        json: { data: users },
      } = await httpClient(`${apiUrl}/users?isMainUser=1&customerId=${id}`, {
        ...getUser(),
      });
      const user = users[0];
      accountForExport.first_name = user?.firstName;
      accountForExport.last_name = user?.lastName;
      accountForExport.email = user?.email;
      accountForExport.user_id = user?.id;

      //get latest plans data
      const {
        json: { data: plans },
      } = await httpClient(
        `${apiUrl}/plans?planType[$in][]=custom&planType[$in][]=main`,
        {
          ...getUser(),
        },
      );

      //check the subscription
      const { json: subs } = await httpClient(
        `${apiUrl}/subscriptions/${subscriptionId}`,
        {
          ...getUser(),
        },
      );
      accountForExport.subscription_active_until = subs.subscriptionActiveUntil;
      accountForExport.status =
        subs.planId == null && subs.status !== 'expired'
          ? 'TRIAL'
          : subs.status === 'active'
          ? subs.status.toUpperCase() +
            ` (${plans.find((x: any) => x.id === subs.planId).planName})`
          : subs.status.toUpperCase();
      accountForExport.forSort = accountForExport.status.includes('ACTIVE')
        ? 1
        : accountForExport.status === 'TRIAL'
        ? 2
        : 3;

      return accountForExport;
    }),
  );

  //sort account ACTIVE > TRIAL > EXPIRED
  const finalExportAccounts = accountsForExport
    .sort((a: any, b: any) => a.forSort - b.forSort)
    .map((x: any) => {
      const { forSort, ...final } = x;
      return final;
    });

  //@ts-ignore
  jsonExport(
    finalExportAccounts,
    {
      headers: [
        'user_id',
        'account_id',
        'first_name',
        'last_name',
        'email',
        'registered_at',
        'status',
        'subscription_active_until',
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      //add month date and time to the filename
      const date = new Date();
      downloadCSV(csv, `accounts - ${date.toLocaleString()}`); // download as 'account-date.csv` file
    },
  );
};

const CustomerList = (props: any) => (
  <List
    title="Accounts"
    exporter={exporter}
    filters={<CustomerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <TextField label="Account ID" source="id" />
      {/* <ReferenceField
        label="User Email"
        source="id"
        reference="users"
        link=""
        emptyText="-">
        <FunctionField render={(record: any) => get(record, 'email')} />
      </ReferenceField> */}

      <TextField source="mainUser.email" label="Email" />

      <ReferenceField
        label="Plan Name"
        source="subscriptionId"
        reference="subscriptions"
        emptyText="-"
        link="">
        <ReferenceField
          source="planId"
          reference="plans"
          // link={(record, reference) => `/${reference}/${record.id}/show`}
          emptyText="-"
          link="">
          <TextField source="planName" emptyText="-" />
        </ReferenceField>
      </ReferenceField>

      <ReferenceField
        label="Subscription Active Until"
        source="subscriptionId"
        reference="subscriptions"
        link="">
        <DateField
          label="Subscription Active Until"
          source="subscriptionActiveUntil"
          emptyText="-"
        />
      </ReferenceField>
      <DateField label="Registered At" source="createdAt" showTime />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default CustomerList;
