import { Datagrid, List, TextField } from 'react-admin';

export const SettingList = (props: any) => (
  <List exporter={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="notes" />
    </Datagrid>
  </List>
);
