import _ from 'lodash';
import React, { useState } from 'react';
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  PasswordInput,
  Button,
  required,
  useNotify,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { getUser, httpClient } from '../../utils/tools';

const ResultDialog = ({ open, handleClose }: any) => {
  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}>
      <DialogTitle disableTypography>Success!</DialogTitle>
      <DialogContent dividers>
        <p>Account information has been sended to the email.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} label="OK" />
      </DialogActions>
    </Dialog>
  );
};

const AdminCreate = (props: any) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const notify = useNotify();

  const onSave = async (data: any) => {
    try {
      await httpClient(`${apiUrl}/users`, {
        method: 'POST',
        body: JSON.stringify({
          strategy: 'email',
          isAdmin: true,
          ..._.pick(data, ['email', 'password']),
        }),
        ...getUser(),
      }).then(async (result: any) => {
        const { json: createdData } = result;
        if (data.rolesId) {
          // await httpClient(`${apiUrl}/user-roles`, {
          //   method: 'POST',
          //   body: JSON.stringify({
          //     userId: createdData.id,
          //     rolesId: data.rolesId,
          //   }),
          //   ...getUser(),
          // })
          //   .then(() => {
          //     setDialogOpen(true);
          //   })
          //   .catch(error => {
          //     throw error;
          //   });
          //NOTE: disable admin can login as customer feature
          // await httpClient(`${apiUrl}/customers`, {
          //   method: 'POST',
          //   body: JSON.stringify({
          //     user: createdData,
          //     customerAsAdmin: true,
          //   }),
          //   ...getUser(),
          // });
        }
      });
    } catch (error) {
      // @ts-ignore
      notify(error.message, 'error');
    }
  };

  const onDialogClose = () => {
    setDialogOpen(false);
    props.history.push(props.basePath);
  };

  return (
    <React.Fragment>
      <ResultDialog open={isDialogOpen} handleClose={onDialogClose} />
      <SimpleForm save={onSave}>
        <TextInput
          type="email"
          label="Email"
          source="email"
          validate={[required()]}
        />
        <PasswordInput label="Password" source="password" />
        <ReferenceInput
          validate={[required()]}
          label="Roles"
          source="rolesId"
          reference="roles">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </React.Fragment>
  );
};

export default AdminCreate;
