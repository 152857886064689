import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import { status } from '../customers/const';

export const SubscriptionEdit = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Subscription info saved`);
    redirect('/customers');
  };
  return (
    <Edit onSuccess={onSuccess} {...props}>
      <SimpleForm redirect={false}>
        <ReferenceInput
          label="Subscription Plan"
          source="planId"
          reference="plans">
          <SelectInput optionText="planName" />
        </ReferenceInput>
        <NumberInput source="mainPlanId" />
        <NumberInput source="maxStatusChecker" />
        <NumberInput source="maxLogin" />
        <NumberInput source="maxAdsAccount" />
        <NumberInput source="isSmsWhatsappEnabled" />
        <DateInput source="subscriptionActiveUntil" />
        <SelectInput source="status" choices={status} />
      </SimpleForm>
    </Edit>
  );
};
