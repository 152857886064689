import React from 'react';
import {
  Datagrid, DateField, Filter,
  List, ReferenceField, SelectInput, TextField, TextInput, UrlField
} from 'react-admin';
import { Helmet } from 'react-helmet';
import StatusField from '../../components/StatusField';
import { status } from './const';

const PageCheckerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search URL" source="url" />
    {/* TODO: add search by Page name */}
    <SelectInput label="Status" source="status" choices={status} />
  </Filter>
);

const PageCheckerList = (props: any) => (
  <List
    title="Page Checkers"
    exporter={false}
    filters={<PageCheckerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Page Checkers</title>
      </Helmet>
      <TextField label="TextChecker ID" source="id" />
      <ReferenceField
        label="Account ID"
        source="page.customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        {/* <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}> */}
        <TextField source="id" />
        {/* </ReferenceField> */}
      </ReferenceField>
      <TextField label="Tracked Text" source="trackedText" />
      <UrlField label="URL" source="url" />
      <TextField label="Page Name" source="page.name" />
      <DateField
        label="Last Checked At"
        source="lastCheckedAt"
        showTime
        emptyText="-"
      />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default PageCheckerList;
