import Button from '@material-ui/core/Button';
import { useNotify, useDataProvider, useRedirect } from 'react-admin';
import React, { useState } from 'react';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const RunCheckerButton = ({ record, checkerType }: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  const runCheckerJob = async () => {
    setLoading(true);

    try {
      const res = await dataProvider.create('checker-jobs', {
        data: {
          checkerId: record.id,
          checkerType,
          ...record,
        },
      });

      // success side effects go here
      redirect(`/checker-jobs/${res.data.id}/show`);
      notify('Checker Job Created');
    } catch (error) {
      notify(`Checker error: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      size="small"
      startIcon={<FindInPageIcon />}
      style={{ marginLeft: '1rem' }}
      onClick={runCheckerJob}>
      Run Checker
    </Button>
  );
};

export default RunCheckerButton;
