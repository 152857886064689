import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ArrayField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

const PlanFilter = (props: any) => (
  <Filter {...props}>
    {/*<TextInput label="Card Brand" source="cardBrand" />*/}
  </Filter>
);

const PlanList = (props: any) => (
  <List
    exporter={false}
    filters={<PlanFilter />}
    // filter={{ planType: 'main' }}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Plans</title>
      </Helmet>
      <TextField label="Plan ID" source="id" />
      <TextField label="Plan Name" source="planName" />
      <TextField label="Amount (in cent)" source="amount" />
      <TextField label="Currency" source="currency" />
      <TextField label="Plan type" source="planType" />
      <ReferenceArrayField
        label="Accounts"
        reference="customers"
        source="customerIds">
        <SingleFieldList linkType={false}>
          <ChipField source="id" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

export default PlanList;
