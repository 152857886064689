import UserList from './UserList';
import UserShow from './UserShow';
import UserIcon from '@material-ui/icons/Group';

export default {
  list: UserList,
  show: UserShow,
  // edit: CustomerEdit,
  icon: UserIcon,
};
