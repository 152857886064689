import { Helmet } from 'react-helmet';
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ChipField,
} from 'react-admin';

const AdminList = (props: any) => (
  <List title="Admins" exporter={false} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Admins</title>
      </Helmet>
      <TextField label="Admin ID" source="id" />
      <ReferenceField label="Email" source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Roles" source="role.rolesId" reference="roles">
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default AdminList;
