import { Title } from 'react-admin';
import { Typography, Grid, Card, CardContent } from '@material-ui/core';
import { getUser, httpClient } from '../utils/tools';
import { apiUrl } from '../utils/const';
import { useEffect, useState } from 'react';

interface ProcessServer {
  name: string;
  result: ProcessStatus[];
}

interface ProcessStatus {
  createdAt: Date;
  environment: string;
  id: number;
  lastOnlineAt: Date;
  monit: { memory: number; cpu: number };
  name: string;
  status: string;
}

const OnlineIndicator = ({
  status,
  ...props
}: {
  status: string;
  [x: string]: any;
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }} {...props}>
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '100%',
        background: status === 'online' ? 'green' : 'red',
        marginRight: '5px',
      }}></div>
    <Typography color="textSecondary">{status}</Typography>
  </div>
);

const Dashboard = () => {
  const [servers, setServers] = useState([]);
  const fetchMotinoring = async () => {
    const res = await httpClient(`${apiUrl}/process-monitor`, {
      method: 'GET',
      ...getUser(),
    });

    setServers(res.json);
  };

  useEffect(() => {
    fetchMotinoring();
  }, []);

  return (
    <div>
      <Title title="Dashboard" />
      {servers.map((server: ProcessServer) => {
        return (
          <>
            <Typography
              key={'server' + server.name}
              style={{ marginTop: '20px' }}
              variant="h4"
              gutterBottom
              color="textSecondary"
              component="div">
              {server.name}
            </Typography>
            <hr />
            <Grid container spacing={2} style={{ marginTop: '20px' }}>
              {server.result?.map(result => (
                <Grid item md={3}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="div" gutterBottom>
                        {result.name}
                      </Typography>
                      <OnlineIndicator status={result.status} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        );
      })}
    </div>
  );
};

export default Dashboard;
