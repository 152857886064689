import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField
} from 'react-admin';
import ExpandableField from '../../components/ExpandableFIeld';

export const RevisionList = (props: any) => (
  <List
    bulkActionButtons={false}
    exporter={false}
    // filter={{ 'user.status': 'inactive' }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}>
    <Datagrid>
      <TextField label="Log Id" source="id" />
      <ReferenceField source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="activity" />
      <TextField source="model" />
      <ExpandableField source='document'/>
      <DateField label="Created At" source="createdAt" showTime={true} />
    </Datagrid>
  </List>
);
