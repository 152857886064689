import { parseJwt } from '../utils/jwt';
import { get } from 'lodash';
import { httpClient } from '../utils/tools';

export default {
  login: ({ username, password }: any) => {
    return httpClient(`${process.env.REACT_APP_API_URL}/authentication`, {
      method: 'POST',
      body: JSON.stringify({
        email: username,
        password: password,
        strategy: 'local',
        isAdmin: true,
      }),
    })
      .then((response: any) => {
        return response.json;
      })
      .then(({ accessToken }) => {
        // store jwt to localstorage
        // then read it's payload
        const payload = parseJwt(accessToken);
        localStorage.setItem('token', accessToken);
        return payload;
      })
      .catch((error: any) => {
        console.log('ERR', error);
      });
  },
  logout: (params: any) => {
    localStorage.removeItem('token');
    localStorage.removeItem('me');
    // localStorage.removeItem('roles');
    // localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkAuth: (params: any) =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    if (status === 403) {
      return Promise.resolve();
    }
    return Promise.resolve();
  },
  getPermissions: (params: any) => Promise.resolve(),
};
