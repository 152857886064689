import * as React from 'react';
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  UrlField,
  Labeled,
  TopToolbar,
  EditButton,
  ReferenceManyField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  linkToRecord,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import RunCheckerButton from '../../components/RunCheckerButton';
import { Link } from 'react-router-dom';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';

const TotalField = (props: any) => <div>{props.total}</div>;

const LinkField = (props: any) => {
  const { record } = props;
  const linkToUser = linkToRecord('/incidents', record.id, 'show');

  return <Link to={linkToUser}>Detail</Link>;
};

const CustomShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <RunCheckerButton record={data} checkerType="pageCheckers" />
  </TopToolbar>
);

const PageCheckerShow = (props: any) => (
  <Show actions={<CustomShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Basic Info">
        <TextField label="TextChecker ID" source="id" />
        <ReferenceField
          label="Customer"
          source="page.customerId"
          reference="customers"
          link={(record, reference) => `/${reference}/${record.id}/show`}
          emptyText="-">
          <ReferenceField
            source="adminUserId"
            reference="users"
            link={record => `/customers/${record.id}/show`}>
            <TextField source="email" />
          </ReferenceField>
        </ReferenceField>
        <TextField label="Tracked Text" source="trackedText" />
        <UrlField label="URL" source="url" />
        <TextField label="Page Name" source="page.name" />
        <DateField
          label="Start Check At"
          source="startCheckAt"
          showTime
          emptyText="-"
        />
        <DateField
          label="End Check At"
          source="endCheckAt"
          showTime
          emptyText="-"
        />
        <DateField
          label="Last Checked At"
          source="lastCheckedAt"
          showTime
          emptyText="-"
        />
        <DateField label="Created At" source="createdAt" showTime />
        <Labeled label="Status">
          <StatusField label="status" source="status" />
        </Labeled>
      </Tab>
      <Tab label="Incident">
        <ReferenceManyField
          addLabel={false}
          reference="incidents"
          filter={{ checkerType: 'pageCheckers' }}
          target="checkerId">
          <div style={{ display: 'flex' }}>
            <Datagrid style={{ width: '100%' }}>
              <TextField label="Incident ID" source="id" />
              <DateField source="createdAt" />
              <TextField source="title" />
              <ReferenceManyField
                sortable={false}
                label="WA Sent"
                reference="alerts"
                filter={{ medium: 'whatsapp' }}
                target="incidentId">
                <TotalField />
              </ReferenceManyField>
              <ReferenceManyField
                sortable={false}
                label="SMS Sent"
                reference="alerts"
                filter={{ medium: 'sms' }}
                target="incidentId">
                <TotalField />
              </ReferenceManyField>
              <ReferenceManyField
                sortable={false}
                label="Email Sent"
                reference="alerts"
                filter={{ medium: 'email' }}
                target="incidentId">
                <TotalField />
              </ReferenceManyField>
              <LinkField />
            </Datagrid>
          </div>
        </ReferenceManyField>
        <ReferenceManyField
          addLabel={false}
          reference="incidents"
          filter={{ checkerType: 'pageCheckers' }}
          target="checkerId">
          <EmptyPlaceholder />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default PageCheckerShow;
