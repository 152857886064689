import {
  ChipField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  EditButton,
  TopToolbar,
} from 'react-admin';

const CustomShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const AdminShow = (props: any) => (
  <Show actions={<CustomShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField label="Admin ID" source="id" />
      <ReferenceField label="Email" source="userId" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField label="Roles" source="role.rolesId" reference="roles">
        <ChipField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default AdminShow;
