import * as React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

const PlanShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Plan ID" source="id" />
      <TextField label="Plan Name" source="planName" />
      <TextField label="Amount" source="amount" />
      <TextField label="Currency" source="currency" />
      <TextField label="Maximum Page" source="maxStatusChecker" />
    </SimpleShowLayout>
  </Show>
);

export default PlanShow;
