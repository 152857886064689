import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import StatusField from '../../components/StatusField';

const InvoiceFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Stripe Invoice ID" source="stripeInvoiceId" alwaysOn />
  </Filter>
);

const InvoiceList = (props: any) => (
  <List
    exporter={false}
    filters={<InvoiceFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <TextField label="Invoice ID" source="id" />
      <ReferenceField
        label="Account ID"
        source="customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        <TextField source="id" />
        {/* <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}>
          <FunctionField
            render={(record: any) =>
              `${record.firstName ?? ''} ${record.lastName ?? ''}`
            }
          />
        </ReferenceField> */}
      </ReferenceField>
      <TextField label="Amount" source="amount" />
      <TextField label="Currency" source="currency" />
      <DateField
        label="Subscription Start Date"
        source="subscriptionStartFrom"
      />
      <DateField
        label="Subscription End Date"
        source="subscriptionActiveUntil"
      />
      <TextField label="Stripe Invoice ID" source="stripeInvoiceId" />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default InvoiceList;
