import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  SelectInput,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { status } from './const';

const IncidentFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput label="Status" source="status" choices={status} resettable />
  </Filter>
);

const IncidentList = (props: any) => (
  <List
    exporter={false}
    filters={<IncidentFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Incidents</title>
      </Helmet>
      <TextField label="Incident ID" source="id" />
      <TextField source="checkerType" />
      <TextField source="checkerId" />
      <TextField label="Title" source="title" emptyText="-" />
      <TextField label="Description" source="description" emptyText="-" />
      <DateField label="Created At" source="createdAt" showTime />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default IncidentList;
