import {
  DateField,
  Show,
  TextField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  ShowController,
  FunctionField,
  TopToolbar,
  useRedirect,
  Button,
  SimpleShowLayout,
  BooleanField,
  ChipField,
  Labeled,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import ShowIcon from '@material-ui/icons/Visibility';
import Chip from '@material-ui/core/Chip';

const ShowAccountButton = ({ record }: { record: any }) => {
  const redirect = useRedirect();

  const handleRedirect = () => {
    console.log(record);
    const customerId = record.customerId;
    redirect(`/customers/${customerId}/show`);
  };

  return (
    <Button
      disabled={!record['customerId']}
      color="primary"
      variant="contained"
      style={{ marginLeft: 20 }}
      onClick={handleRedirect}
      label="Show Account"
      startIcon={<ShowIcon />}
    />
  );
};

const UserShowActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ShowAccountButton record={data} />
  </TopToolbar>
);

const UserShow = (props: any) => (
  <ShowController {...props}>
    {controllerProps => (
      <Show actions={<UserShowActions />} {...props} {...controllerProps}>
        <SimpleShowLayout>
          {/* <TabbedShowLayout>
          <Tab label="Main User Info"> */}
          {controllerProps.record?.isMainUser && (
            <Chip label="Main User" color="primary" />
          )}
          <TextField label="User ID" source="id" />
          <TextField label="First Name" source="firstName" />
          <TextField label="Last Name" source="lastName" />
          <TextField label="Email" source="email" />
          <DateField label="Registered At" source="createdAt" showTime />
          <ReferenceField
            label="Status"
            source="customerId"
            reference="customers"
            sortable={false}
            link="">
            <StatusField label="status" source="status" />
          </ReferenceField>
        </SimpleShowLayout>
        {/* </Tab> */}
        {/* <Tab label="Subscribtion Info">
            {controllerProps?.record &&
              controllerProps?.record?.customer?.status !== 'trial' && (
                <ReferenceField
                  label="Plan Name"
                  source="customerId"
                  reference="customers"
                  emptyText="Trial"
                  link={false}>
                  <ReferenceField
                    label="Plan Name"
                    source="subscriptionId"
                    reference="subscriptions"
                    emptyText="Trial"
                    link={false}>
                    <ReferenceField
                      label="Plan Name"
                      source="planId"
                      reference="plans"
                      emptyText="Trial"
                      link={false}>
                      <TextField source="planName" />
                    </ReferenceField>
                  </ReferenceField>
                </ReferenceField>
              )}
            {controllerProps?.record &&
              controllerProps?.record?.customer?.status === 'trial' && (
                <FunctionField
                  label="Plan Name"
                  render={() => `14 Days Trial`}
                />
              )}
            <ReferenceField
              label="Card Brand"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <TextField label="Card Brand" source="cardBrand" emptyText="-" />
            </ReferenceField>
            <ReferenceField
              label="Card Last Four"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <TextField
                label="Card Last Four"
                source="cardLastFour"
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField
              label="Card Exp Month"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <TextField
                label="Card Exp Month"
                source="cardExpMonth"
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField
              label="Card Exp Year"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <TextField
                label="Card Exp Year"
                source="cardExpYear"
                emptyText="-"
              />
            </ReferenceField>
            <ReferenceField
              label="Subscription Active Since"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <ReferenceField
                label="Subscription Active Since"
                source="subscriptionId"
                reference="subscriptions"
                link={false}>
                <DateField
                  label="Subscription Active Since"
                  source="subscriptionActiveSince"
                  emptyText="-"
                  showTime
                />
              </ReferenceField>
            </ReferenceField>
            <ReferenceField
              label="Subscription Active Until"
              source="customerId"
              reference="customers"
              emptyText=""
              link={false}>
              <ReferenceField
                label="Subscription Active Since"
                source="subscriptionId"
                reference="subscriptions"
                link={false}>
                <DateField
                  label="Subscription Active Until"
                  source="subscriptionActiveUntil"
                  emptyText="-"
                  showTime
                />
              </ReferenceField>
            </ReferenceField>
          </Tab> */}
        {/* </TabbedShowLayout> */}
      </Show>
    )}
  </ShowController>
);

export default UserShow;
