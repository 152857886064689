import RolesList from './RolesList';
import RolesCreate from './RolesCreate';
import RolesShow from './RolesShow';
import RolesEdit from './RolesEdit';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';

export default {
  list: RolesList,
  create: RolesCreate,
  show: RolesShow,
  edit: RolesEdit,
  icon: PermDataSettingIcon,
};
