import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  FunctionField,
  ReferenceField,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import CheckerTypeField from '../../components/CheckerTypeField';
import JSONPretty from 'react-json-pretty';
import { get } from 'lodash';

const FormCheckerShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="CheckerJob ID" source="id" />
      <TextField source="checkerType" />
      <TextField source="checkerId" label="Checker ID" />
      <FunctionField
        label="Log"
        render={(record: any) => <JSONPretty data={get(record, 'log')} />}
      />
      <ReferenceField
        label="Incident ID"
        source="incidentId"
        reference="incidents"
        link={(record, reference) => `/${reference}/${record.incidentId}/show`}
        emptyText="-">
        <TextField source="id" />
      </ReferenceField>
      <TextField label="Created At" source="createdAt" emptyText="-" />
      <Labeled label="Status">
        <StatusField label="status" source="status" />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);

export default FormCheckerShow;

// const CheckerTypeField = ({ label, record = {}, source }: IField) => {
//   const checkerUrl =
//     record.checkerType === 'pageCheckers' ? 'page-checkers' : 'form-checkers';

//   return (
//     <a href={`/#/${checkerUrl}/${record.id}/show`}>
//       {checkerType[record.checkerType]}
//     </a>
//   );
// };
