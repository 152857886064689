import PropTypes from 'prop-types';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    accordion: {
      boxShadow: 'none',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: '#3f51b5',
    },
    bodyText: {
      width: '100%',
    }
  }),
);

function ExpandableField(props: any) {
  const classes = useStyles();
  const { source, record } = props;

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<MoreHorizIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className={classes.heading}>See details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.bodyText}>
            {record[source]}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

ExpandableField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ExpandableField;
